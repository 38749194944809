/* eslint-disable camelcase*/
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  EventWrapperNewUI,
  AboutEventDiv,
  EventRightSectionRowMainDiv,
  QuizButton,
  LeaderboardText,
} from  "../../components/EventDetails/styles";
import { withRouter } from "react-router-dom";
import {
  convertMonthFromDate,
  fetchApi,
  timeConvertInHrsMins,
  timeConvertInHrsMinsSec,
} from "../../utils/methods";
import {
  ContainerSecond,
  ContainerFirst,
} from  "../../components/EventDetails/styles";
// import { convertDateInTimezone } from "../../utils/methods";
import { withTranslation } from "react-i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ToolTipNew } from "../../components/AdminTeamCreation/styles";
import { BuddyTabContainer,BuddyTab } from "../../components/PeopleHomeV2/styles";
import {  StyledSvgNew } from "../../components/MainEvents/styles";

import {
  // AdminEventsCalendarIcon,
  WellnessCalendarIcon,
  AdminEventsRewardsIcon,
  AdminEventsTimeIcon,
  LaunchEventIcon,
  LeaderboardNoUserIcon,
  QuizAttendeeIcon,
  TotalQuestionsIcon,
} from "../../utils/icons";
import parse from 'react-html-parser';
import EventImageCard from "../../components/EventDetails/eventImageCard";
import Timer from "../../components/EventDetails/timer";
import { toast } from "react-toastify";
import { WellnessQuizeDetails } from "../../redux/constants/apiConstants";
import PointsPopup from "./PointsPopup";
// import LazyImage from "../../components/common/LazyImage/LazyImage";
// import { ImageUrl } from "../../utils/constants"; 
import QuizLeaderBoard from "./QuizLeaderBoard";
// import LazyImage from "../../components/common/LazyImage/LazyImage";
import SkeletonLoder from "../../components/common/skeletonLoder";

class QuizDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatedImage: false,
      imgSrc: [],
      showChatScreen: 0,
      time: [],
      buttonStatus: 0,
      selectedTab: 1,
      showCalendar: false,
      showBack: false,
      showSpinner: true,
      isButtonDisabled: false,
      quizDetail:null,
      leaderboardDetails:null,
      quizLength:null,
      detailsPopup:false,
      quizResponse:this.props.location?.state?.quizResponse,
      totalUsers: null,
      totalQuestions:null,
      totalQuizTime: null,
      timerCompleted: false,
      loading:false,
    };
    
  }

  componentDidMount() {
    this.getWellnessQuizDetails(this.props.match.params.id)
    // this.getLeaderboardQuizDetails() 
    
    
    if (this.props.location?.state?.showPopup) {
      this.setState({ detailsPopup: true });
    }
    else{
      this.setState({ detailsPopup: false });
    }

    if (this.props.location.state === "chat") {
      this.setState({ selectedTab: 2 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // window.console.log("this.props.match.params.id",this.props.match.params.id)
    if (
      this.state.quizDetail &&
      prevState.quizDetail !== this.state.quizDetail &&
      moment(this.state.quizDetail.start_date).isAfter(new Date())
    ) {
      this.setState({ timerCompleted: false });
    }

    if(this.props.match.params.id != prevProps.match.params.id){

      this.getWellnessQuizDetails(this.props.match.params.id)
    }

    if (!this.state.showSpinner) {
      window.setTimeout(() => {
        this.setState({ showSpinner: true });
      }, 3000);
    }
  
  }


  handleTimerCompletion = () => {
    this.setState({ timerCompleted: true });
  };

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    // clearInterval(this.interval);
  }

  getWellnessQuizDetails = async(quizId)=>{
    // let apiUrl = WellnessQuizeDetails + "/" + 2;
    // const quizId = this.props.match.params.id;
    // window.console.log("quizid",quizId)
    this.setState({loading:true});
    let apiUrl = `${WellnessQuizeDetails}/${quizId}`;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({loading:false});
        this.setState({quizDetail: res?.data[0]})
        this.setState({totalQuestions:res?.data[0]?.question_count})
        this.setState({totalQuizTime:res?.data[0]?.quize_time})
        this.setState({totalUsers:res?.data[0]?.total_join_users_count || 0})

      }else if (res.status === 'failed'){
        toast.error(res.data.message);
        this.setState({loading:false});
        this.props.history.push({
          pathname: "/education/adventure",
        });
      }
    } catch (error) {
      window.console.log(error);
    }
  }


 
    
 
  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };
 

  setActiveTab = (active) => {
    this.setState(
      {
        selectedTab: active,
      },
     
    );
  };

  showButton = () => {
    this.setState({
      buttonStatus: !this.state.buttonStatus,
    });
  };
  

  eventButtonStatusHover = (value) => {
    if (value === "delete")
      this.setState({
        eventDelete: true,
      });
    else if (value === "invite") {
      this.setState({
        eventInvite: true,
      });
    } else if (value === "back") {
      this.setState({
        showBack: true,
      });
    } else {
      this.setState({
        eventEdit: true,
      });
    }
  };

  eventButtonStatus = (value) => {
    if (value === "delete")
      this.setState({
        eventDelete: false,
      });
    else if (value === "invite") {
      this.setState({
        eventInvite: false,
      });
    } else if (value === "back") {
      this.setState({
        showBack: false,
      });
    } else {
      this.setState({
        eventEdit: false,
      });
    }
  };

  calculateMinuits = (targetDate) => {
    let parsedDate1 = new Date(targetDate);
    let parsedDate2 = new Date();
    let difference = (difference = Math.abs(parsedDate2 - parsedDate1));
    if (difference <= 0) {
      return "Target date has already passed";
    }
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let showButton =
      days === 0 && hours === 0 && minutes > 0 && minutes < 31 ? true : false;
    return showButton;
  };

 
 
  headerView = () => {
    const {  selectedTab, showBack } =
      this.state;
  
  
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );


    // window.console.log("this.props.location.state",this.props.location.state.showPopup)

    return (
      <BuddyTabContainer marginBottom="20px">
        <ToolTipNew
          margin={"auto 0px auto 0px"}
          active={showBack}
          onMouseEnter={() => this.eventButtonStatusHover("back")}
          onMouseLeave={() => this.eventButtonStatus("back")}
          height="40px"
          width="40px"
          padding
        >
          <OverlayTrigger
            placement="bottom"
            overlay={popover(this.props.t("Back"))}
          >
            <div
              className='="toolTip'
              onClick={
                ()=> { if(window.location.pathname.includes('wellness-resource')){
                  this.props.history.push("/education/adventure");}}
              }
            >
              <StyledSvgNew active={showBack} width="25px" height="25px">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <mask id="a" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path fill="#007AB1" d="M.645 0h24v24h-24z"/>
                  </mask>
                  <g mask="url(#a)">
                    <path d="M6.395 12c0-.358.15-.717.45-.99l9.428-8.6a1.64 1.64 0 0 1 2.172 0c.6.547.6 1.434 0 1.981L10.102 12l8.343 7.609c.6.547.6 1.434 0 1.98a1.64 1.64 0 0 1-2.172 0l-9.43-8.599a1.34 1.34 0 0 1-.448-.99" fill="#007AB1"/>
                  </g>
                </svg>
              </StyledSvgNew>
            </div>
          </OverlayTrigger>
        </ToolTipNew>
        <BuddyTab
          width
          padding
          active={selectedTab === 1}
          onClick={() => this.setActiveTab(1)}
          margin={"10px 0px 10px 10px"}
        >
          {this.props.t("Overview")}
        </BuddyTab>
        <BuddyTab
          width
          padding
          active={selectedTab === 2}
          onClick={() => this.setActiveTab(2)}
          margin={"10px 0px 10px 10px"}
        >
          {this.props.t("Leaderboard")}
        </BuddyTab>
      </BuddyTabContainer>
    );
  };

  popover = (value) => (
    <Popover
      id="popover-basic"
      style={{
        width: "auto",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #005c87",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#005c87",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </Popover>
  );

  showCalendarData = () => {
    this.setState((prev) => ({ showCalendar: !prev.showCalendar }));
  };


  //   onQuizRedirection = () => {
  //     const { history } = this.props;
  //     history.push({pathname:`/education/adventure/quiz-questions`});
  //   };

  //   onQuizRedirection = () => {
  //     const { history } = this.props;
  //     const { quizDetail } = this.state;
    
  //     history.push({
  //       pathname: '/education/adventure/quiz-questions',
  //       state: {
  //         quizTitle: quizDetail?.title,
  //         quizTime: moment(quizDetail?.start_date).format("h:mm A"), // Only time
  //       }
  //     });
  //   };


  onQuizRedirection = () => {
    const { history } = this.props;
    const { quizDetail } = this.state;
    
    history.push({
      pathname: '/wellness-resource/quiz-questions',
      state: {
        quizTitle: quizDetail?.category_name,
        quizTime: quizDetail?.quize_time, 
        quizId: quizDetail?.id,
        quizType:  quizDetail?.quiz_type,
        quizPoints:quizDetail?.quiz_point
      }
    });
  };
  
  
  
  hideCalendar = (id) => {
    this.setState(
      (prev) => ({ showCalendar: !prev.showCalendar, eventId: id }),
      () => {
        this.props.attendEvent(id, 0, 1);
      }
    );
  };


  showCalendar = (id, title, event) => {
    event.stopPropagation();
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar,
      eventId: id,
      eventName: title,
    }));
  };

   
  

  showOverviewSection = () => {
    const { t } = this.props;
    const { selectedTab, quizDetail } = this.state;

    return (
      <ContainerFirst width="100%" padding="0">
        {<EventImageCard event={quizDetail} />}
        {selectedTab === 1 && (
          <>
            <AboutEventDiv>
              <div className="aboutTitle">
                {t("About the Quiz")}
              </div>
              <div className="aboutDescription">
                {parse(quizDetail?.description)}
              </div>
            </AboutEventDiv>
          </>
        )}
      </ContainerFirst>
    );
  };


  // showLeaderBoardSection = () => {
    
  //   const { selectedTab, leaderboardDetails } = this.state;
  //   const quizJoinUsers = leaderboardDetails?.data?.quiz_join_users || [];
  //   // this.setState({quizLength: quizJoinUsers.length})
  //   // window.console.log("quizJoinUsers-----", quizJoinUsers);
  
  //   const getBackgroundColor = (index) => {
  //     if (index === 0) return "rgba(118, 171, 120, 0.15)"; 
  //     if (index === 1) return "rgba(241, 201, 119, 0.15)"; 
  //     if (index === 2) return "rgba(175, 135, 193, 0.15)"; 
  //     return "#fff"; 
  //   };
  
  //   return (
  //     <ContainerFirst width="100%" padding="0">
  //       {selectedTab === 2 && (
  //         <>
  //           <div className="title">Top Leaders</div>
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               fontSize: "16px",
  //               fontFamily: "Rubik",
  //               alignItems: "center",
  //               margin: "auto",
  //               padding: "10px",
  //               color: "#005C87",
  //               borderRadius: "6px",
  //               border: "1px solid rgba(0, 92, 135, 0.30)",
  //               background: "#FFFF",
  //               width: "752px",
  //               height: "40px",
  //             }}
  //           >
  //             <div style={{ width: "40%" }}>Users</div>
  //             <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>Ranks</div>
  //             <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>Answers</div>
  //             <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>Time</div>
  //           </div>
  //           {quizJoinUsers.map((user, index) => (
  //             <div
  //               key={user.uid}
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "row",
  //                 width: "752px",
  //                 fontSize: "16px",
  //                 fontFamily: "Rubik",
  //                 height: "80px",
  //                 borderRadius: "6px",
  //                 border: "1px solid rgba(0, 92, 135, 0.30)",
  //                 background: getBackgroundColor(index),
  //                 marginTop: "20px",
  //                 color: "#005C87",
  //                 alignItems: "center",
  //                 padding: "10px",
  //               }}
  //             >
  //               <div style={{width: "40%",display:"flex",alignItems:"center"}}>
  //                 <LazyImage style={{ width: "48px",height: "48px",margin: "10px 15px 10px 0px",borderRadius:"48px"}} onError={this.fallBack} src={`${ImageUrl}/${user.profile_image}`}/>
  //                 <div style={{  fontFamily: "Rubik-medium" }}>{user.name}</div>
  //               </div>
  //               <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>
  //                 {index + 1}
  //                 <span style={{ fontSize: "smaller", position: "relative", top: "-1px" }}>
  //                   {index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"} 
  //                 </span> &nbsp;Rank
  //               </div>
  //               <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>
  //                 {user.correct_answer} Correct
  //               </div>
  //               <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
  //                 {Math.floor(user.total_time)} sec
  //               </div>
  //             </div>
  //           ))}
  //         </>
  //       )}
  //     </ContainerFirst>
  //   );
  // };


  showLeaderBoardSection = () => {
    const { selectedTab, totalUsers,quizDetail,timerCompleted } = this.state;
    const quizId = this.props.match;
  
    if (totalUsers === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '162px',marginBottom:'162px' }}>
          <div>{LeaderboardNoUserIcon()}</div>
          <LeaderboardText>No one has taken the quiz yet. Be the first to take it and <br></br> inspire others to get their results!</LeaderboardText>
          {moment(quizDetail?.start_date).isAfter(new Date()) && !timerCompleted ? (
            <QuizButton bgColor={"rgba(0, 92, 135, 0.10)"}  isMargin={"24px auto"} width={"368px"}>
              <Timer
                data={moment(quizDetail?.start_date)}
                onComplete={this.handleTimerCompletion}
              />
            </QuizButton>
          ) : (
            <QuizButton isCursor={"pointer"} isMargin={"24px auto"} width={"368px"} onClick={() => this.onQuizRedirection()}>
              {LaunchEventIcon()} Start the Quiz
            </QuizButton>
          )}
        </div>
      );
    }
  
    return <QuizLeaderBoard selectedTab={selectedTab} match={quizId} />;
  };
  


  closeDetailPopup=()=>{
    this.setState({detailsPopup:false})
  }



  

  render() {
    const {
      t,
    } = this.props;
    const {
      selectedTab,
      quizDetail,
      detailsPopup,
      quizResponse,
      // leaderboardDetails,
      totalUsers,
      totalQuestions,
      totalQuizTime,
      timerCompleted,
      loading
      // quizLength
    } = this.state;

   
   
    const lang = localStorage.getItem("lang");
 

    return (
      <>
        <div className="containerDiv" style={{marginTop:"40px"}}>
          {this.headerView()}
        </div>
        { loading ?  <div style={{width:'1246px', margin:'auto'}}>  <SkeletonLoder width={"1246px"} height={"700px"}/> </div> :
          <div style={{display:"flex",width:"1248px",margin:"auto auto 40px auto", background:"white", padding:"20px", borderRadius:"4px"}}>
            <div className="leftContainer" style={{minWidth:"750px", maxWidth:"750px"}}>
              {selectedTab === 1 ? this.showOverviewSection():this.showLeaderBoardSection() }
            </div>
           
            <div className="rightContainer" style={{minWidth:"400px", marginLeft:"20px"}}>
              <ContainerSecond width="100%" isButton="436px" isDirection={true}>
                <div>
                  <div className="topLabelDiv">
                    {quizDetail && selectedTab === 1 ? (
                      quizDetail?.is_user_quiz_completed === 0 ? (
                        moment(quizDetail?.start_date).isAfter(new Date()) && !timerCompleted ? (
                          <div className="label blueLabel" style={{ minWidth: "260px" }}>
                            <Timer
                              data={moment(quizDetail?.start_date)}
                              onComplete={this.handleTimerCompletion}
                            />
                          </div>
                        ) : (
                          <div className="label blueLabel" style={{ minWidth: "260px" }}>
                            <QuizButton isCursor={"pointer"} onClick={this.onQuizRedirection}>
                              {LaunchEventIcon()} Start the Quiz
                            </QuizButton>
                          </div>
                        )
                      ) : (
                        <QuizButton bgColor={"rgba(0, 92, 135, 0.10)"} color={"#005C87"} isSize={"18px"} width={"436px"}>
                          You have already taken the quiz
                        </QuizButton>
                      )
                      
                    ) : (
                      <div className="label" style={{ minWidth: "260px" }}>
                        <QuizButton isFont={"Rubik-medium"} bgColor={"rgba(0, 92, 135, 0.10)"} color={"#007AB1"} isSize={"18px"}>
                          {/* <LazyImage
                            style={{ width: "24px", height: "20px" }}
                            onError={() => window.console.error("Image failed to load")}
                            src={`${ImageUrl}/${quizDetail?.category_image}`}
                          />{" "} */}
                          {quizDetail?.category_name}
                        </QuizButton>
                      </div>
                    )}
                    { quizDetail && selectedTab === 1 ?
                      <div className="label" style={{ minWidth: "260px" }}>
                        <QuizButton isFont={"Rubik-medium"} bgColor={"rgba(0, 92, 135, 0.10)"} color={"#007AB1"} isSize={"18px"}>
                          {/* <LazyImage
                            style={{ width: "24px", height: "20px" }}
                            onError={() => window.console.error("Image failed to load")}
                            src={`${ImageUrl}/${quizDetail?.category_image}`}
                          />{" "} */}
                          {quizDetail?.category_name}
                        </QuizButton>
                      </div>:null }
                  </div> 
                  {/* <div className="topLabelDiv">
                    {quizDetail && selectedTab === 1  ? (
                      
                    quizDetail?.is_user_quiz_completed === 0 ? ( (moment(quizDetail?.start_date > new Date())) ? (
                      <div className="label blueLabel" style={{ minWidth: "260px" }}>
                        <Timer data={moment(quizDetail?.start_date)} />
                      </div>
                    ) : (
                      <div className="label blueLabel" style={{ minWidth: "260px" }}>
                        <QuizButton onClick={() => this.onQuizRedirection()}>
                          {LaunchEventIcon()} Start the Quiz
                        </QuizButton>
                      </div>
                    ) ) :  <QuizButton bgColor={"rgba(0, 92, 135, 0.10)"} color={"#005C87"} isSize={"18px"}  >
                      You have already taken the quiz
                    </QuizButton> 
                  ) : <div className="label blueLabel" style={{ minWidth: "260px" }}>
                    <QuizButton isFont={"Rubik-medium"} bgColor={"rgba(0, 92, 135, 0.10)"} color={"#007AB1"} isSize={"18px"}  >
                      <LazyImage
                        style={{ width: "24px", height: "20px" }}
                        onError={() => window.console.error("Image failed to load")}
                        src={`${ImageUrl}/${quizDetail?.category_image}`}
                      /> {quizDetail?.category_name}
                    </QuizButton>
                  </div>}
                  </div> */}
                  {
                    (selectedTab === 1 || selectedTab === 2) &&  (
                      <EventWrapperNewUI  isRow={true} >
                     
                        <EventRightSectionRowMainDiv style={{ borderTop:"1px solid rgba(0,92,135,0.10)",paddingTop:"25px"}}>
                          <div className="imageDiv">
                            {WellnessCalendarIcon()}
                          </div>
                          <div className="details">
                            <div>
                              { (lang !== 'fr') ? (convertMonthFromDate(moment(quizDetail && quizDetail?.start_date).format("ddd, DD MMM, YYYY"), this.props)) : convertMonthFromDate(moment(quizDetail && quizDetail.start_date).format("ddd, DD MMM YYYY"), this.props)}
                            </div>
                          </div>
                        </EventRightSectionRowMainDiv>
   
                        <EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {AdminEventsTimeIcon()}
                          </div>
                          <div className="details">
                            {quizDetail?.quiz_type === 0 ?
                              (<div>
                                {timeConvertInHrsMins(Math.floor((moment(totalQuizTime/60)), 'minutes'))}
                              </div>):
                              (<div>
                                {timeConvertInHrsMinsSec(Math.floor((((totalQuizTime * totalQuestions )))))}
                              </div>)
                            }
                          </div>
                        </EventRightSectionRowMainDiv>
  
                        {selectedTab === 2 && <EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {TotalQuestionsIcon()}
                          </div>
                          <div className="details">
                            <div>
                              {totalQuestions} Questions
                            </div>
                          </div>
                        </EventRightSectionRowMainDiv>}
                        
                        {quizDetail?.quiz_point !== null && quizDetail?.quiz_point !== 0 ?
                          (<EventRightSectionRowMainDiv>
                            <div className="imageDiv">
                              {(AdminEventsRewardsIcon())}
                            </div>
                            <div className="details">
                              <div> +{quizDetail?.quiz_point} {t("Points")}</div>
                            </div>
                          </EventRightSectionRowMainDiv>) : null}
  
  
                        {selectedTab === 2 && totalUsers > 0 ? (<EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {(QuizAttendeeIcon())}
                          </div>
                          <div className="details">
                            <div>{totalUsers} Attendees</div>
                          </div>
                        </EventRightSectionRowMainDiv>): null}
                      </EventWrapperNewUI>
                      
                    )}
                </div>
              
  
              </ContainerSecond>
            </div>
          </div>}
        {detailsPopup && <PointsPopup showModal={detailsPopup} 
          onClose={this.closeDetailPopup} quizResponse={quizResponse}  setActiveTab={this.setActiveTab}   />}
      
      </>
    );
  }
}

QuizDetails.propTypes = {
  history: PropTypes.object.isRequired,
  eventDetail: PropTypes.object,
  isLoading: PropTypes.bool,
  profileImage: PropTypes.string,
  attendEvent: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
  location: PropTypes.object,
  role: PropTypes.string,
  companyInfo: PropTypes.object.isRequired,
  t: PropTypes.func,
  networkError: PropTypes.string,
  isAdminEvent: PropTypes.bool,
  match:PropTypes.object
};

const mapStateToProps = (state) => ({
  eventDetail: state.events.eventDetail,
  comments: state.events.comments,
  attendees: state.events.attendees,
  attending: state.events.attending,
  isLoading: state.events.isLoading,
  userId: state.profileData.userId,
  companyDetails: state.profileData.userCompany,
});


export default withRouter(
  connect(mapStateToProps, null)(withTranslation()(QuizDetails))
);
